@import url('https://fonts.googleapis.com/css2?family=Geist+Mono:wght@100..900&display=swap');






/* Container for centering cards */
.aboutWrapper {
    width: auto;
    height: auto;
}

.aboutCard {
    padding: 10px;
    border-style: solid;
    margin: 20px;
    width: 500px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center; /* Centers content inside the card */
}

.aboutTitle {
    font-size: 30px;
    text-align: center;
    font-family: "Geist Mono", monospace;
    font-weight: bold;
}

.educList {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    margin: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    
}

.workList {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    margin: auto;
    margin-bottom: 0px;
}

.abtImg {
border-radius: 10px;
}

.eduCardImg, .workCardImg  {
    
    border-radius: 10px;
    display: grid;
    width: auto;
    height: auto;
    background-color: rgba(13, 55, 172, 0.822);
    justify-content: center;
    padding: 10px;
    align-items: center;

}

/******************************************* WORK *******************************/


.workCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px;   
    align-items: center; 
    padding: 10px;
    width: auto;
    
}


.workCaption {
    display: grid;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    text-align: center;
    justify-content: center;
    place-items: center;
    border-radius: 10px;
    width: 180px;
    height: 40px;
    padding: 5px;
    margin-bottom: 2.5px;
    background-color: rgba(31, 83, 224, 0.89);
    font-size: medium;
    color: white;
    line-height: 1.2;
    place-items: center;
    margin-top: -20px;
    border-style: solid;
    border-color: black;
}

.workCard li {
    flex-grow: 1; /* Allows items to grow and occupy available space */
    text-align: center; /* Centers text inside each list item */
}



.workCardContainer:hover .workCardInfo {
    opacity: 1;
    transform: translateY(0);
    display: inline-block;
    
  }  

.workCardInfo {
    display: grid;
    place-items: center;
    opacity: 0;
    transform: translateY(-30px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 180px;
    height: 65px;
    margin: 2.5px; /* Add spacing around the element */
    background-color: rgba(31, 83, 224, 0.89);
    color: white;
    font-size: medium;
    padding: 5px;
    border-style: solid;
    border-color: black;
  }


  .workCardContainer:hover .workCaption {
    opacity: 1;
    transform: translateY(0);
}
/************ EDUC *******************************/




.eduCardContainer {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    margin: -13px auto;   
    align-items: center; 
    padding: 10px;   
  }


.eduCardInfo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color:rgba(225, 228, 236, 0.89);
    border-style: solid;
    border-color:black;
    font-size: large;
    border-radius: 10px;
    padding: 5px;
    color: white;
    align-items: center; 
    opacity: 0;
    transform: translateY(20%); /* Start below the image */
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
    margin-bottom: 0px;

  }

.eduCardInfo li {
    width: auto;
    height: auto;
    border-style: solid;
    border-radius: 10px;
    padding: 5px;
    font-size: large;
    
    text-align: center;
    background-color:rgba(31, 83, 224, 0.89);
    line-height: 1.2;
    margin-top:  1px;
    margin-bottom:  0px;
    
}




.eduCardContainer:hover .eduCardInfo {
    opacity: 1; /* Make the info visible */
  transform: translateY(0); /* Move the info upwards to cover the image */
  }  


  .eduCardContainer:hover .eduCardInfo {
    opacity: 1; /* Make the info visible */
    transform: translateY(0); /* Move the info upwards to cover the image */
  }



.educCard li {
    flex-grow: 1; /* Allows items to grow and occupy available space */
    text-align: center; /* Centers text inside each list item */
}

/* Optional reset for lists */
ul, li {
    list-style: none;    
    justify-content: center;
    align-items: center;
}



a {
    color: white;
  }



    /***************************************************** Tablet (768px to 1199px) */
    @media (min-width: 768px) and (max-width: 1199px) {
   
 @media (max-width: 767px) {
   
    .aboutWrapper {
        width: auto;
        height: auto;
        margin: 0;
        overflow-x: hidden;
        align-items: center;
        align-content: center;
        
    }

        
    .aboutTitle {
        margin-top: 100px;
        font-size: 30px;
        text-align: center;
        font-family: 'Konkhmer Sleokchher', sans-serif;
    }
    
  
    
    .workCardImg img {
    border-radius: 10px;
    height: 100px;
    width: 100px;
    }
    
    .workCardImg  {
        
        border-radius: 10px;
        display: grid;
        max-width: auto;
        height: auto;
        background-color: rgba(13, 55, 172, 0.822);
        justify-content: center;
        padding: 5px;
        align-items: center;
    
    }
    

}


       /**** WORK *******************************/

    .aboutEduc {
        
        align-content: center;
        align-items: center;
    }


    .workCaption {
        display: grid;
        opacity: 0;
        transform: translateY(30px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        text-align: center;
        justify-content: center;
        place-items: center;
        border-radius: 10px;
        width: 200px;
        height: 40px;
        padding: 5px;
        margin-top: -20px;
        margin-bottom: 2.5px;
        background-color: rgba(31, 83, 224, 0.89);
        font-size: medium;
        color: white;
        line-height: 1.2;
        place-items: center;
        
        
       
    }
    
    .workCard li {
        flex-grow: 1; /* Allows items to grow and occupy available space */
        text-align: center; /* Centers text inside each list item */
    }
    
    .workCardContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 5px;   
        margin-top: 20px;
        margin-bottom: 50px;
        align-items: center; 
        padding: 10px;
        width: auto;
    }
    
    .workCardContainer:hover .workCardInfo {
        opacity: 1;
        transform: translateY(0);
      }  
    
    
    .workCardInfo {
        display: grid;
        place-items: center;
        opacity: 0;
        transform: translateY(-30px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 200px;
        height: 65px;
        margin-top: 2.5px;
        margin-bottom: -50px;
        background-color: rgba(31, 83, 224, 0.89);
        color: white;
        font-size: medium;
        padding: 5px;
      }
    
    
      .workCardContainer:hover .workCaption {
        opacity: 1;
        transform: translateY(0);
    }
    /************ EDUC *******************************/
    
    
    .educList {
        display: flex;
        flex-wrap: wrap;
        width: 700px;
        height: auto;
        justify-content: center; 
        margin-top: 20px;
        
        align-items: center;
        align-self: center;
    
    }
    
    
    
    
    .eduCardContainer {
        display: inline-block;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        margin: 40px;  
        align-items: center; 
        padding: 0px;
        position: block;
      }
    
    .eduCardInfo {
        position: absolute;
        place-content: center;
        background-color:rgba(225, 228, 236, 0.89);
        border-style: solid;
        border-color:rgba(31, 83, 224, 0.89);
        width: 200px;
        height: auto;
        border-radius: 10px;
        padding: auto;
        color: white;
        align-items: center; 
        opacity: 0; /* Start as hidden */
        transform: translateY(100%); /* Start below the image */
        transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
        margin-bottom: 5px;
      }
    
    .eduCardInfo li {
        width: auto;
        height: auto;
        border-style: solid;
        border-radius: 10px;
        padding: 5px;
        font-size: small;
        margin: auto;
        text-align: center;
        background-color:rgba(31, 83, 224, 0.89);
        line-height: 1.2;
        
      
        
    }
    
    
    
    
    .eduCardContainer:hover .eduCardInfo {
        opacity: 1; /* Make the info visible */
      transform: translateY(0); /* Move the info upwards to cover the image */
      }  
    
    
      .eduCardContainer:hover .eduCardInfo {
        opacity: 1; /* Make the info visible */
        transform: translateY(0); /* Move the info upwards to cover the image */
      }
    
    
    
    .educCard li {
        flex-grow: 1; /* Allows items to grow and occupy available space */
        text-align: center; /* Centers text inside each list item */
    }
    
    /* Optional reset for lists */
    ul, li {
        list-style: none;    
        justify-content: center;
        align-items: center;
    }
    





    }
    



   /***************************************************** Mobile (up to 767px) */
   @media (max-width: 767px) {
   
    .aboutWrapper {
        width: auto;
        height: auto;
        margin: 0;
        overflow-x: hidden;
    }

        
    .aboutTitle {
        margin-top: 100px;
        font-size: 30px;
        text-align: center;
        font-family: 'Konkhmer Sleokchher', sans-serif;
    }
    
    .educList, .workList {
        display: flex;
        flex-direction: column;
        justify-content: center; 
        margin: -10px;
    }
    
    .abtImg {
    border-radius: 10px;
    }
    
    .workCardImg  {
        
        border-radius: 10px;
        display: grid;
        max-width: auto;
        height: auto;
        background-color: rgba(13, 55, 172, 0.822);
        justify-content: center;
        padding: 5px;
        align-items: center;
    
    }
    

   








       /**** WORK *******************************/

    .aboutEduc {
        
        align-content: center;
        align-items: center;
    }


    .workCaption {
        display: grid;
        opacity: 0;
        transform: translateY(30px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        text-align: center;
        justify-content: center;
        place-items: center;
        border-radius: 10px;
        width: 200px;
        height: 40px;
        padding: 5px;
        margin-top: -20px;
        margin-bottom: 2.5px;
        background-color: rgba(31, 83, 224, 0.89);
        font-size: medium;
        color: white;
        line-height: 1.2;
        place-items: center;
        
        
       
    }
    
    .workCard li {
        flex-grow: 1; /* Allows items to grow and occupy available space */
        text-align: center; /* Centers text inside each list item */
    }
    
    .workCardContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 20px;   
        align-items: center; 
        padding: 10px;
        width: auto;
    }
    
    .workCardContainer:hover .workCardInfo {
        opacity: 1;
        transform: translateY(0);
      }  
    
    
    .workCardInfo {
        display: grid;
        place-items: center;
        opacity: 0;
        transform: translateY(-30px);
        transition: opacity 0.3s ease, transform 0.3s ease;
        text-align: center;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        width: 200px;
        height: 65px;
        margin-top: 2.5px;
        margin-bottom: -50px;
        background-color: rgba(31, 83, 224, 0.89);
        color: white;
        font-size: medium;
        padding: 5px;
      }
    
    
      .workCardContainer:hover .workCaption {
        opacity: 1;
        transform: translateY(0);
    }
    /************ EDUC *******************************/
    
    .eduCardContainer {
        display: inline-block;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        margin-top: 20px;   
        align-items: center; 
        padding: 0px;
        position: block;
      }
    
    .eduCardInfo {
        position: absolute;
        place-content: center;
        background-color:rgba(225, 228, 236, 0.89);
        border-style: solid;
        border-color:rgba(31, 83, 224, 0.89);
        width: 200px;
        height: auto;
        border-radius: 10px;
        padding: auto;
        color: white;
        align-items: center; 
        opacity: 0; /* Start as hidden */
        transform: translateY(100%); /* Start below the image */
        transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
        margin-bottom: 5px;
      }
    
    .eduCardInfo li {
        width: auto;
        height: auto;
        border-style: solid;
        border-radius: 10px;
        padding: 5px;
        font-size: small;
        margin: auto;
        text-align: center;
        background-color:rgba(31, 83, 224, 0.89);
        line-height: 1.2;
        
      
        
    }
    
    
    
    
    .eduCardContainer:hover .eduCardInfo {
        opacity: 1; /* Make the info visible */
      transform: translateY(0); /* Move the info upwards to cover the image */
      }  
    
    
      .eduCardContainer:hover .eduCardInfo {
        opacity: 1; /* Make the info visible */
        transform: translateY(0); /* Move the info upwards to cover the image */
      }
    
    
    
    .educCard li {
        flex-grow: 1; /* Allows items to grow and occupy available space */
        text-align: center; /* Centers text inside each list item */
    }
    
    /* Optional reset for lists */
    ul, li {
        list-style: none;    
        justify-content: center;
        align-items: center;
    }
    
    
    
    a {
        color: white;
      }


      .eduCardImg {
        border-radius: 10px;
        display: grid;
        width: 190px;
        height: auto;
        background-color: rgba(13, 55, 172, 0.822);
        justify-content: center;
        padding: 10px;
        padding-left: 10px;
        align-items: center;
    
        }

   }