

.homeWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    margin-bottom: 0px;
    
    padding: 0px;
}

.techandQuotes {
  
}


    /* Desktop (1200px and up) */
@media (min-width: 1200px) {
    .meImg {
      height: 500px; 
      position: block;
    background-color: rgba(31, 83, 224, 0.89);
    background-image: url(./css-images/homeImgBg.jpg);
    background-size: cover; /* Ensures the image covers the entire element */
    background-position: center; /* Centers the image in the element */
    background-repeat: no-repeat;
 
  object-fit: contain;

    border-style: solid;
    border-color: white;
    margin-top: 50px;
       padding: 0;
       padding-bottom: 0;
       border-top-left-radius: 50px;
       border-bottom-left-radius: 50px;
       border-top-right-radius: 20px;
       border-bottom-right-radius: 20px;
       
    }


    .homeImg {
        border-bottom-right-radius: 150px;  
        border-bottom-left-radius: 150px;
    }

   

    .intro {
        width: 500px;
       border-style: solid;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 30px;
        padding: 15px;
        font-size: large;
        color: white;
        margin-bottom: 20px;
        background-color: rgba(31, 83, 224, 0.89);
       
       } 
       
       
       
       .techs {
           width: auto;
           height: auto;
           border-radius: 10px;
           justify-content: center; /* Center items horizontally */
           align-items: center; /* Center items vertically */
           margin: 10px;
           margin-top: 10px;
           
       }
       
       .tech-list {
       
           display: flex;
           flex-direction: row;
           flex-wrap: wrap;
           justify-content: center; /* Center items in the list */
           align-items: center; /* Center items vertically */
           padding: 0; /* Remove any default padding */
           margin: 0; /* Remove any default margin */
           list-style: none; /* Remove bullet points from the list */
       }
       
       .tech-item {
           width: auto;
           height: auto;
           
           margin: 15px; /* Space between items */
           display: flex; 
           justify-content: center; 
           align-items: center; 
           text-align: center; 
          
           
         
       }
       
       .techImg {
           border-radius: 15px;
           color:rgba(31, 83, 224, 0.89);
           background-color: rgba(31, 83, 224, 0.89);
           border-style: solid;
           padding: 2px ;
           
       }
       
       
       .tech-item:hover {
           transform: rotate(15deg); /* Adjust degrees as needed */
           transition: transform 0.2s ease;
       }

       .quotes {
       height: 400px;
        margin: 10px;
        margin-top: 10px;
        
       
    }
       
       .quoteIntro {
       border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 30px;
        border-style: solid;
        padding: 15px;
        font-size: large;
        color: white;
        border-style: solid;
        margin-bottom: 20px;
        background-color: rgba(31, 83, 224, 0.89);
        width: 300px;
       }
       
       
       
       .quote {
           margin: 5px;
           margin-left: 1px;
           display: flex;
          
           width: auto;
       }
       
       .qImgContainer {
           
          margin: 5px;
           border-top-right-radius: 30px;
           border-bottom-right-radius: 30px;
           border-top-left-radius: 30px;
           
           
       }
       
       .qImg{ 
           border-top-right-radius: 30px;
           border-bottom-right-radius: 30px;
           border-top-left-radius: 30px;
           color:rgba(31, 83, 224, 0.89);
           border-style: solid;
           padding: 2px;
       
           color:rgba(31, 83, 224, 0.89);
           background-color: rgba(31, 83, 224, 0.89);
           
       }
       
       
       
       .qWords {
           transform: translateX(100%);
           transition: opacity 0.3s ease, transform 0.3s ease;
           opacity: 0;
           width: 500px;
           border-top-right-radius: 30px;
           border-bottom-right-radius: 30px;
           border-top-left-radius: 30px;
           border-style: solid;
           border-bottom: white;
           background-color: rgba(31, 83, 224, 0.89);
           color: white;
           padding: 15px;
           font-size: large;
           
       }
       
       .qImgContainer:hover + .qWords {
           transform: translateX(0);
           opacity: 1;
           
       }
       
       .qImgContainer:hover  {
       transform: rotate(5deg); /* Adjust degrees as needed */
       transition: transform 0.2s ease;
           
       }
       



  }
  
  /*************************************************************** Tablet (768px to 1199px) */
  @media (min-width: 768px) and (max-width: 1199px) {


    .homeWrapper {
        display: flex;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        margin-bottom: 0px;
        
        padding: 0px;
        overflow-x: hidden;
        overflow-y: hidden;
       
    }

    .meImg {
        content: url(./css-images/me2.png);
        height: 500px;
        width: 370px;
    margin: 0;
    padding: 0;
    margin-top: 250px;
    margin-left: 0px;
    border-style: solid;
    border-color: white;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    background-color: rgba(31, 82, 224, 0.825);
    
   
        
    }

    .intro {
        display: block;
        width: 600px;
       border-style: solid;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 30px;
        padding: 15px;
        font-size: medium;
        color: white;
        margin-bottom: 20px;
        background-color: rgba(31, 83, 224, 0.89);
       
       } 

       .techandQuotes {
        display: flex;
        flex-direction: column;
        height: auto;
        width: auto;
        margin: 0px;
       
    }
       
       
       
       .techs {
        display: flex;
        flex-wrap: wrap;
           width: 300px;
           height: auto;
           border-radius: 10px;
           justify-content: center; /* Center items horizontally */
           align-items: center; /* Center items vertically */
           margin-top: 80px;
           margin-left: 10px;
       }
       
       .tech-list {
           display: flex;
           position: block;
           flex-direction: row;
           flex-wrap: wrap;
           justify-content: center; /* Center items in the list */
           align-items: center; /* Center items vertically */
           padding: 0; /* Remove any default padding */
           margin: 0; /* Remove any default margin */
           list-style: none; /* Remove bullet points from the list */
       }
       
       .tech-item {
           width: 60px;
           height: 60px;
           border-radius: 10px;
          
           margin: 10px; /* Space between items */
           display: flex; 
           justify-content: center; 
           align-items: center; 
           text-align: center; 
          
           
         
       }
       
       .techImg {
        width: 50px;
        height: 50px;
           border-radius: 10px;
           color:rgba(31, 83, 224, 0.89);
           background-color: rgba(31, 83, 224, 0.89);
           border-style: solid;
           padding: 0px ;
           
       }
       
       
       .tech-item:hover {
           transform: rotate(15deg); /* Adjust degrees as needed */
           transition: transform 0.2s ease;
       }
       .quotes {
        margin:5px;
        width: 350px;
      
     height: auto;  
     align-content: center;
    }

       .quoteIntro {
    position: block;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 30px;
        padding: 15px;
        font-size: medium;
        border-style: solid;
        color: white;
        margin-bottom: 20px;
        background-color: rgba(31, 83, 224, 0.89);
        width: 320px;
    
       }
       
      
       
       .quote {
           margin: 5px;
           margin-left: 1px;
           display: flex;
          height: auto;
           width: auto;
       }
       
       .qImgContainer {
           
           margin-right: 10px;
           border-top-right-radius: 30px;
           border-bottom-right-radius: 30px;
           border-top-left-radius: 30px;
           
       }
       
       .qImg{ 
width: 50px;
    height: 50px;
     
           border-top-right-radius: 30px;
           border-bottom-right-radius: 30px;
           border-top-left-radius: 30px;
           color:rgba(31, 83, 224, 0.89);
           border-style: solid;
           padding: 2px;
           color:rgba(31, 83, 224, 0.89);
           background-color: rgba(31, 83, 224, 0.89);
           
       }
       
       
       
       .qWords {
           transform: translateX(100%);
           transition: opacity 0.3s ease, transform 0.3s ease;
           opacity: 0;
           width: 350px;
           border-top-right-radius: 30px;
           border-bottom-right-radius: 30px;
           border-top-left-radius: 30px;
           background-color: rgba(31, 83, 224, 0.89);
           border-style: solid;
           color: white;
           padding: 15px;
           font-size: small;
           
       }
       
       .qImgContainer:hover + .qWords {
           transform: translateX(0);
           opacity: 1;
           
       }
       
       .qImgContainer:hover  {
       transform: rotate(5deg); /* Adjust degrees as needed */
       transition: transform 0.2s ease;
           
       }
       
    
  }
  
  /****************************************************************** Mobile (up to 767px) */
  @media (max-width: 767px) {

    .homeWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;
        height: auto;
        width: auto; /**300**/
        margin: 0;
        overflow-x: hidden;
       

    }
    
    
   
    .meImg {
      content: url(./css-images/me2.png);
      height: 30px;
      width: 30px;
      display: none;
      margin-bottom: 0%;
     
   
    }



    .intro {
        width: 300px;
       border-style: solid;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 30px;
        padding: 15px;
        font-size: small;
        color: white;
        margin-top: 100px;
        background-color: rgba(31, 83, 224, 0.89);
       
       } 
       .techandQuotes {
        display: flex;
        flex-direction: column;
        height: auto;
        width: auto;
        margin: 0px;
       
    }
       
       
       .techs {
           width: 350px;
           height: auto;
           margin: 0px;
           border-radius: 10px;
           justify-content: center; /* Center items horizontally */
           align-items: center; /* Center items vertically */
           
           
       }
       
       .tech-list {
           display: flex;
           flex-direction: row;
           flex-wrap: wrap;
           padding: 0%;
           justify-content: center; /* Center items in the list */
           align-items: center; /* Center items vertically */
           padding: 0; /* Remove any default padding */
           margin: 0; /* Remove any default margin */
           list-style: none; /* Remove bullet points from the list */
       }
       
       .tech-item {
           width: 60px;
           height: 60px;
           margin: 15px; /* Space between items */
           display: flex; 
           justify-content: center; 
           align-items: center; 
           text-align: center; 
          
           
         
       }
       
       .techImg {
           border-radius: 10px;
           color:rgba(31, 83, 224, 0.89);
           background-color: rgba(31, 83, 224, 0.89);
           border-style: solid;
           padding: 2px ;
           height: 50px;
            width: 50px;
           
       }
       
       
       .tech-item:hover {
           transform: rotate(15deg); /* Adjust degrees as needed */
           transition: transform 0.2s ease;
       }
       
       .quoteIntro {
        width: 300px;
       border-style: solid;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 30px;
        padding: 15px;
        font-size: small;
        color: white;
    
        background-color: rgba(31, 83, 224, 0.89);
       }
       
       .quotes {
           margin: 10px;
           width: auto;
           padding: auto;
           
          
       }
       
       .quote {
           margin: 5px;
           padding: 5px;
           display: flex;

           width: auto;
       }
       
       .qImgContainer {
      
           border-top-right-radius: 30px;
           border-bottom-right-radius: 30px;
           border-top-left-radius: 30px;
           
       }
       
       .qImg{ 
           border-top-right-radius: 30px;
           border-bottom-right-radius: 30px;
           border-top-left-radius: 30px;
           color:rgba(31, 83, 224, 0.89);
           border-style: solid;
           padding: 2px;
           margin: 5px;
           color:rgba(31, 83, 224, 0.89);
           background-color: rgba(31, 83, 224, 0.89);
           width: 50px;
           height: 50px;
           
       }
       
       
       
       .qWords {
           transform: translateX(100%);
           transition: opacity 0.3s ease, transform 0.3s ease;
           opacity: 0;
           width: 250px;
           border-top-right-radius: 30px;
           border-bottom-right-radius: 30px;
           border-top-left-radius: 30px;
           background-color: rgba(31, 83, 224, 0.89);
           color: white;
           padding: 10px;
           font-size: small;
           
       }
       
       .qImgContainer:hover + .qWords {
           transform: translateX(0);
           opacity: 1;
           
       }
       
       .qImgContainer:hover  {
       transform: rotate(5deg); /* Adjust degrees as needed */
       transition: transform 0.2s ease;
           
       }
       
  }


  




