
    /***************************************************** Desktop (1200px and up) */
    @media (min-width: 1200px) {

        .footerStyle {
            display: flex;
            justify-content: flex-end; /* Aligns content to the right */
            align-items: center; /* Vertically center content */
            background-color: #00001c;
            padding: 5px;
            width: 100%; /* Full width */
            background-color: #00001c; /* Footer background color */
            margin-top: auto; /* Pushes footer to the bottom */
          }
        
        
        .socials {
        color: white;
        margin-right: 20px;
        font-size: xx-small;
        font-style: italic;
        }
    
    }
    
    /***************************************************** Tablet (768px to 1199px) */
    @media (min-width: 768px) and (max-width: 1199px) {
        
.footerStyle {
    display:flex;
    width: 100%;
    background-color: #00001c;
    padding: 0;
    height: 100%;
    
}


.socials {
    color: white;
    margin-right: 20px;
    font-size: xx-small;
    font-style: italic;
}
     
    }
    
    /***************************************************** Mobile (up to 767px) */
    @media (max-width: 767px) {
        
.footerStyle {
    display:flex;
    width: 100%;
    height: 20%;
    background-color: #00001c;
    padding: 0;
    overflow-x: hidden;
    
}


.socials {
    color: white;
    margin-right: 20px;
    font-size: xx-small;
    font-style: italic;
}
     
      }
    
    


