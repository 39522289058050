
@import url('https://fonts.googleapis.com/css2?family=Geist+Mono:wght@100..900&display=swap');





 /************************************************************************* Desktop (1200px and up) */
 @media (min-width: 1200px) {
    
  .headerStyle {
    display: block;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10%;
    background-color: rgb(3, 3, 3);
}
  
  
  .navList {
        display: flex;
        color: white;
        display: none;
        }   

        .navList {
        display: flex;
        color: white;
        }   
        
        .logo {
            margin-left:  80px;
            margin-right: -150px;
            margin-top: 20px;
            color: white;
            font-family: "Geist Mono", monospace;
            font-size: larger;
            font-weight: 900;
            float: left;
          
        }
        
        
        
        .headerMenu {
            display: flex;
            list-style: none;
            
        }
        
        .menuItem {
        margin: 30px;
        font-weight: bolder;
        font-family: "Geist Mono", monospace;
        }
        
        
            .navList a {
                font-family: 'Konkhmer Sleokchher', sans-serif;
                color: white;
                
            }
        
        
            .navList a {
                font-family: 'Konkhmer Sleokchher', sans-serif;
                color: white;
                text-decoration: none;
              }
              
              .navList a.active {
                color: rgba(13, 55, 172, 0.822); /* Blue color for the active link */
                padding: 10px;
                background-color: white;
                border-radius: 50px;
                border-color: whitesmoke;
              }
              
              .hamburger {
                display: none;
              }


              .navs {
                display: flex;
            flex-direction: column;  }



            .menuItemModal {
                color: white;
            }
           
  }
  
  /************************************************************************** Tablet (768px to 1199px) */
  @media (min-width: 768px) and (max-width: 1199px) {
    .navList {
        display: flex;
        color: white;
        display: none;
        }   


        .headerStyle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: rgb(3, 3, 3);
        }
        
        
        .navList {
        display: flex;
        color: white;
        }   
        
        .logo {
            margin: 30px;
            color: white;
            font-family: "Geist Mono", monospace;
            font-weight: bolder;
        }
        
        
        
        .headerMenu {
            display: flex;
            list-style: none;
            
        }
        
        .menuItem {
          margin: 30px;
          font-weight: bolder;
          font-family: "Geist Mono", monospace;
        }
        
        
            .navList a {
                font-family: 'Konkhmer Sleokchher', sans-serif;
                color: white;
                
            }
        
        
            .navList a {
                font-family: 'Konkhmer Sleokchher', sans-serif;
                color: white;
                text-decoration: none;
              }
              
              .navList a.active {
                color: rgba(13, 55, 172, 0.822); /* Blue color for the active link */
                padding: 10px;
                background-color: white;
                border-radius: 50px;
                border-color: whitesmoke;
              }
              

    .hamburger {
                display: none;
              }
  
  }
  
  /************************************************************************** Mobile (up to 767px) */
  @media (max-width: 767px) {
    .headerStyle {
      display: flex;
      width: 100%;
      height: 60px;
      background-color: rgb(3, 3, 3);
      overflow-x: hidden;
      position: fixed;  /* Ensures relative positioning for absolute positioning of the menu */
      top: 0; /* Ensure the header stays at the top */
      left: 0; /* Ensure the header stays at the left side */
      z-index: 1000; /* Ensures header is always on top */
    }
  
    .navList {
      display: relative;
      flex-direction: column;
      position: fixed;
      top:60px; /* Position the menu directly below the header */
      right: -5px;
      width: 120px;
      background-color: rgba(132, 122, 151, 0.5);
      color: white;
      transform: translateY(-200%);  
      transition: transform 0.3s ease-in-out;  /* Smooth sliding effect */
      z-index: 10;  /* Ensure the menu appears above the header */
      background-color: rgba(13, 55, 172, 0.822);
      justify-items: center;
        font-weight: bolder;
        font-family: "Geist Mono", monospace;
    }
  
  /* When the menu is open, slide it into view below the header */
  .headerMenu.is-Open .navList {
    transform: translateX(0); /* Move the menu down into view */
  }

    .logo {
    margin-top: 20px;
    margin-left: 10px;
      margin-right: 1px;
      color: white;
      display: block;
      font-family: "Geist Mono", monospace;
      font-weight: 900;
    }
  
    .hamburgerImg {
      width: 30px;
      height: 30px;
      border-radius: 10px;
      margin-top: 15px;
      margin-left: 215px;
    }
  
    .headerMenu {
     display: flex;
     flex-direction: row;
     position: block;
    }
  
    /* When the menu is open, slide it into view below the header */
    .headerMenu.is-Open .navList {
      transform: translateY(0); /* Move the menu down into view */
    }
  
    .navList a {
      font-family: 'Konkhmer Sleokchher', sans-serif;
      color: white;
      text-decoration: none;
     
    }
  
    .navs {
      display: flex;
      flex-direction: column;
    }
  }
  