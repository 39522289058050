.portfolioStyle {
  width: 100%;
  height: 100%;
  
  justify-content: center; 
  align-items: center;
  display: flex;
  flex-direction: column;
}

.portHeader {
  font-size: 30px;
  text-align: center;
  font-family: "Geist Mono", monospace;
  font-weight: bold;
}

.classProjects {
  display: flex;
  flex-direction: row;
  align-self: center;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;



  
}

.projectCard {
  justify-items: center;
  justify-content: center;
 
  padding: 5px;
  margin: 50px;
  margin-top: 10px;
  width: auto;
  height: auto;
}

.projNameAndTech {
  position: absolute;
  justify-items: center;
  border-style: solid;
  transform: translateY(-100%); /* Slide down from the top */
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  font-size: large;
  padding: 5px;
  padding-bottom: 10px;
  background-color:rgba(31, 83, 224, 0.89);
  color: white;
  height: 50px;
  width: 356px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
 
}

.projectCard:hover .projNameAndTech {
  opacity: 1; /* Make it visible */
  transform: translateY(0); 
  
}
  
  

.techUsed {
display: flex;
justify-content: center; 
list-style: none;
margin-top: 2px;
padding: 1px;

}

.techUsed li {
  margin: 5px;
 
}




.projImg {
  border-radius: 10px;
  border-style: solid;
  border-color: rgba(31, 83, 224, 0.89);;
}
  
  


.projInfo {
  position: relative;
  bottom: 100px;
  left: 0;
  width: 300px;
  height: 100px;
  border-style: solid;
  text-align: center;
  opacity: 0;
  font-size: medium;
  height: 70px;
  width: 356px;
 padding: 5px;
 background-color:rgba(31, 83, 224, 0.89);
 color: white;
 border-bottom-left-radius: 10px;
 border-bottom-right-radius: 10px;
  top: auto;
  transform: translateY(100%); /* Slide down from the top */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
  
}

.projectCard:hover .projInfo {
  opacity: 1; /* Make it visible */
  transform: translateY(-100%); /* Move to its original position */
  bottom: 0; /* Position it at the bottom of the container */
}



.techUsedLi {
  border-radius: 3px;
  border-style: solid;
  color: white;

}



  /***************************************************** Tablet (768px to 1199px) */
  @media (min-width: 768px) and (max-width: 1199px) {


    .classProjects {
      display: flex;
      flex-direction: row;
      
      width: 100%;
      height: 100%;
      flex-wrap: wrap;
      align-items: center;
    
      
    }
    
    .projectCard {
      justify-items: center;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin: auto;
      margin-top: 50px;
      width: auto;
      height: auto;
    }
    
    .projNameAndTech {
      position: absolute;
      justify-items: center;
      border-style: solid;
      transform: translateY(-100%); /* Slide down from the top */
      transition: opacity 0.3s ease, transform 0.3s ease;
      opacity: 0;
      font-size: medium;
      padding: 5px;
      padding-bottom: 10px;
      background-color:rgba(31, 83, 224, 0.89);
      color: white;
      height: 50px;
      width: 300px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
     
    }
    
    .projectCard:hover .projNameAndTech {
      opacity: 1; /* Make it visible */
      transform: translateY(0); 
      
    }
      
      
    
    .techUsed {
    display: flex;
    justify-content: center; 
    list-style: none;
    margin-top: 2px;
    padding: 1px;
    
    }
    
    .techUsed li {
      margin: 5px;
     
    }
    
    
    
    
    .projImg {
      width: 300px;
      height: 180px;
      border-radius: 10px;
      border-style: solid;
      border-color: rgba(31, 83, 224, 0.89);;
    }
      
      
    
    
    .projInfo {
      position: relative;
      bottom: 100px;
      left: 0;
      width: 300px;
      height: 60px;
      border-style: solid;
      text-align: center;
      opacity: 0;
      font-size: small;
     padding: 5px;
     background-color:rgba(31, 83, 224, 0.89);
     color: white;
     border-bottom-left-radius: 10px;
     border-bottom-right-radius: 10px;
      top: auto;
      transform: translateY(100%); /* Slide down from the top */
      transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
      
    }
    
    .projectCard:hover .projInfo {
      opacity: 1; /* Make it visible */
      transform: translateY(-100%); /* Move to its original position */
      bottom: 0; /* Position it at the bottom of the container */
    }
    
    
    
    .techUsedLi {
      border-radius: 3px;
      border-style: solid;
      color: white;
    
    }
    












   
  }



  /***************************************************** Mobile (up to 767px) */
  @media (max-width: 767px) {

.portfolioStyle {
  margin-top: 90px;
}


    
.projImg {
  width: 320px;
  height: 180px;
  
}

.projNameAndTech {
  width: 310px;
  height: 50px;

}

.projInfo {
  width: 310px;
  height: 80px;

}

.projInfo {
  position: relative;
  bottom: 100px;
  left: 0;
  width: 310px;
  height: 50px;
  border-style: solid;
  text-align: center;
  opacity: 0;
  font-size: small;
 padding: 5px;
 background-color:rgba(31, 83, 224, 0.89);
 color: white;
 border-bottom-left-radius: 10px;
 border-bottom-right-radius: 10px;
  top: auto;
  transform: translateY(100%); /* Slide down from the top */
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
  
}


   
  }
