

/***************************************************** Desktop (1200px and up) */
@media (min-width: 1200px) {

    .entirePage {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        height: 100vh; /* Full viewport height */
        margin: 0;
        padding: 0;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto; /* Allows vertical scrolling */
      }
      
      .contentStyle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start; /* Content aligns at the top */
        flex: 1; /* Ensures content takes up the available space */
        width: 100%;
        max-width: 100%;
        padding: 20px;
        box-sizing: border-box;
        background-image: url(./bgImg2.jpg);
        background-repeat: unset;
        background-size: 100% 100%;
      }
        
}

/***************************************************** Tablet (768px to 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {

    .entirePage {
        background-color: #f5f5f5; /* Base color */
      
        background-repeat: repeat; /* Ensures the texture tiles if it's small */
        background-size: cover; /* Optional, adjusts texture to cover the whole background */
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 100%;
        height: 100%;
        margin-top: 0px;
}

.contentStyle {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 50px;
    box-sizing: border-box;
    flex: 1; /* Allows content to expand and push footer down */ }
 
}





/***************************************************** Mobile (up to 767px) */
@media (max-width: 767px) {


    .entirePage {
        background-color: #0307fa65; /* Base color */
       
        background-image: url(./bgImg2.jpg);
        background-size: cover; /* Optional, adjusts texture to cover the whole background */
        
        flex-direction: column;
        
    }
    
    
    .contentStyle {
       
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
        margin: auto;
        padding: 20px;
        padding-bottom: 50px;
        box-sizing: border-box;
        flex: 1; /* Allows content to expand and push footer down */
        margin-bottom: 0;  /* Remove any margin that could push footer down */
        padding-bottom: 0; /* Remove padding if present */
    
    
    
    
    }
  }

