.contactIntro {
    width: 600px;
    height: 90px;
    border-style: solid;
    border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        border-top-left-radius: 30px;
        padding: 15px;
    background-color: rgba(31, 83, 224, 0.89);
    color: white;
    font-size: large;

}


.contactInfo {
    flex: row;
    position: grid;
    place-items: center;
}


.contactItems {
height: 70px;
width: 550px;
padding: 10x;
margin: 40px;

padding: auto;
display: flex;
text-align: center;
align-items: center;
position: grid;
    place-items: center;
}



.contactchan {
    height: 30px;
    width: 300px ;
    padding: auto;
    padding: 5px;
    margin: 10px;
   font-size: medium;
   color: white;
    background-color: rgba(0, 0, 0, 0.753);
    border-radius: 10px;
    position: grid;
    place-items: center;
    border-style: solid;
    border-color: rgba(31, 83, 224, 0.89);
    
}





.contactDet {
    width: 300px ;
    height: 30px;
    padding: 10px;
    

    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.3s ease, transform 0.3s ease;
    border-radius: 10px;
    color: white;
    background-color: rgba(31, 83, 224, 0.89);
    border-style: solid;
    border-color: black;
    position: grid;
    place-items: center;
    
} 



.plzImgContainer {
    opacity: 0;
    margin-bottom: -30px;
}

.contactItems:hover .contactDet {
    opacity: 1;
    transform: translateX(0);
}

.contactItems:hover ~ .plzImgContainer {
    opacity: 1;
    transition: opacity 1.8s ease;
}


.plzImg { 
margin-bottom: -25px;

}


  /***************************************************** Tablet (768px to 1199px) */
  @media (min-width: 768px) and (max-width: 1199px) {
   
  }
  
  /***************************************************** Mobile (up to 767px) */
  @media (max-width: 767px) {

    .contactStyle {
        display: flex;
        flex-direction: column;
        margin-top: 80px;
        align-items: center;
        align-content: center;
        width: 100%; /* Ensure it takes up full width of its container */
        max-width: 100vw; /* Prevent it from exceeding viewport width */
        height: auto;
        overflow-x: hidden; /* Prevent horizontal scrolling */
        overflow-y: hidden;
        
        

    }
 
    .contactIntro {
margin: auto;
        width: 300px;
        height: auto;
        font-size: medium;
      


    }




    .contactItems {
        height: 90px;
        width: 550px;
       
        margin: 30px;
        
        padding: 2px;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        position: grid;
            place-items: center;
           
        }

    .contactDet {
        height: 30px;
        width: 290px ;
        padding: auto;
     margin-top: 2px;
    
        opacity: 0;
        transform: translateY(-100%);
        transition: opacity 0.3s ease, transform 0.3s ease;
        border-radius: 10px;
        color: white;
        background-color: rgba(31, 83, 224, 0.89);
        position: grid;
        place-items: center;
        border-style: solid;
       
    } 
    
    
   
    
   



}


   
    




  